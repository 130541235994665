.temp {}

.container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.section1 {
    /*   border: 2px solid red; */
    position: relative;
    flex: 60%;
}

.section2 {
    padding: 10px;
    /*   border: 2px solid blue; */
    /*   border: 2px solid  black; */
    flex: 30%;
}

.checkout {
    text-align: initial !important;
}

.checkout .productTitle {
    letter-spacing: 0.1em;
    color: #000;
    margin-bottom: 0;
    font-size: 17px !important;
    line-height: 18px;
    font-family: Futura, sans-serif !important;
}

.checkout .colorDivU,
.checkout .colorDivL {
    width: 20px;
    height: 20px;
    display: inline-block !important;
    margin-right: 5px;
    cursor: pointer;
}

.checkout .sizeDivU,
.checkout .sizeDivL {
    display: inline-block !important;
    margin-right: 5px;
    color: black;
    /*   border: 1px solid #5c5c5c; */
    border: 1px solid #ccc;
    font-size: 10px !important;
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 1;
    padding: 8px 8px;
    cursor: pointer;
}

.checkout .optTitle {
    font-family: Futura, sans-serif;
    letter-spacing: 0.5px;
    max-width: 80px;
    padding: 0;
    color: #7f7f7f;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 5px;
    font-size: 13px;
}
.checkout .varDivs {
    margin-bottom: 5px;
}

.checkout .priceDiv {
    display: block !important;
    font-family: Futura, sans-serif;
    letter-spacing: 0.1em;
    font-size: 14px !important;
    line-height: 18px;
    margin-top: 0px;
    padding: 8px 0 16px;
    color: black;
}

.checkout .highlight {
    border: 1px solid black;
}

.checkout .btn {
    width: 100%;
    color: #ffffff;
    border: 1px solid #5c5c5c;
    background-color: #5c5c5c;
    padding: 14px 28px;
    line-height: normal;
    border-radius: 0;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.2em;
    font-family: Futura, sans-serif;
    cursor: pointer;
}

.checkout .btn:hover {
    border: 1px solid #ffffff;
    background-color: black;
}

.checkout .btn.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
}

.checkout .btn.disabled:hover {
    /* Override hover styles */
    /* Add any specific styles you want for the disabled hover state */
}

.checkout .msgDiv {
    text-align: center;
    margin: 3px;
    padding: 2px;
    display: none;
    border-radius: 5px;
}

@media screen and (max-width: 450px) {
    .checkout .productTitle {
        font-size: 15px !important;
    }
}