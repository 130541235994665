.feedbackScreen {
    font-family: Futura, sans-serif;
    width: 50%;
    margin: auto;
    position: absolute;
    border-radius: 10px;
    background: #EAEAEA;
    z-index: 100;
    padding: 5px 5px;

    top: 35%;
    left: 0;
    right: 0;
    margin: auto;
    color: #313131;
}

.feedbackScreen .crossSvg {
    display: block;
    margin-left: auto;
    cursor: pointer;
}

.feedbackScreen .title {
    font-size: 17px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 10px;
}

.feedbackScreen .smileCont {
    display: flex;
    justify-content: center;
    align-items:flex-start;
    margin-bottom: 5px;
}

.feedbackScreen .feedbackTxt{
    display: flex;
    width: 215px;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.feedbackScreen .feedbackTxt h5 {
    margin: unset;
    font-weight: 500;
    font-size: 16px;
}

.feedbackScreen .smileCont .smileSvg {
    margin-right: 13px;
    width: 30px;
    cursor: pointer;
}

.feedbackScreen .submitBtn {
    font-family: Futura, sans-serif;
    background-color: #313131;
    color: white;
    border-radius: 18px;
    width: 34%;
    padding: 6px 0px;
    border: none;
    font-size: 20px;
    font-weight: 500;
}

@media screen and (max-width: 500px) {
    .feedbackScreen {
        width: 66%;
    }
    .feedbackScreen .feedbackTxt{
        margin-bottom: 4px;
    }

    .feedbackScreen .title {
        font-size: 15px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 10px;
    }
    
    .feedbackScreen .feedbackTxt h5 {
        margin: unset;
        font-weight: 500;
        font-size: 14px;
    }
    
    .feedbackScreen .smileCont .smileSvg {
        margin-right: 13px;
        width: 26px;
        cursor: pointer;
    }
    
    .feedbackScreen .submitBtn {
        font-family: Futura, sans-serif;
        background-color: #313131;
        color: white;
        border-radius: 18px;
        width: 34%;
        padding: 6px 0px;
        border: none;
        font-size: 15px;
        font-weight: 500;
    }
}