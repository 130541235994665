div .MainDressViewer {
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: auto;
  display: block;
}

.MainDressViewer .canvas {
  z-index: 1;
  position: unset !important;
  height: 100%;
  background: white;
  display: block;
  margin: auto;
}

.DressNav .dress-container {
  height: 100%;
  width: auto;
}

.DressNav .img {
  height: 100%;
  width: auto;
  margin: 0;
}

.MainDressViewer .buttonTop {
  top: 25%;
}

.MainDressViewer .buttonBottom {
  bottom: 25%;
}

.MainDressViewer .btnprev {
  left: 5%;
}

.MainDressViewer .btnnext {
  right: 5%;
}

/* .MainDressViewer .nav-buttons {
  display: flex;
}

.MainDressViewer .buttonContainerTop {
  width: 80%;
  position: absolute;
  top: 25%;
  z-index: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.MainDressViewer .buttonContainerBottom {
  width: 80%;
  position: absolute;
  bottom: 25%;
  z-index: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
} */

.MainDressViewer .button-html {
  width: 100%;
}

.MainDressViewer .button-exit {
  margin: auto;
  display: block;
  z-index: 12;
  background: none;
  border: none;
}

.MainDressViewer .dress-container-button {
  height: 14em;
  width: auto;
  border: 1px solid grey;
  padding: 0px;
  background-color: white;
}

.MainDressViewer .button {
  background: none;
  border: 0;
  height: 3em;
  position: absolute;
  cursor: pointer;
  user-select: none;
}

.MainDressViewer .buttonImage {
  height: 100%;
}

/* .MainDressViewer .buttonImage:active, .buttonPressed{
  height: 80%;
} */

.MainDressViewer .buttonPressed {
  height: 2.7em;
}

.MainDressViewer .initial-loading-gif {
  height: 100%;
  position: absolute;
  top: 0;
  margin: auto;
  right: 0;
  left: 0;
  z-index: 3;
  user-select: none;
}

.MainDressViewer .helper-toggle {
  left: 6%;
  position: absolute;
  top: 5.5%;
  z-index: 2;
  cursor: pointer;
  user-select: none;
}

.MainDressViewer .avatar-toggle-reset-controls {
  right: 6%;
  position: absolute;
  top: 2%;
  display: flex;
  flex-direction: column;
  z-index: 2;
  height: 95px;
  width: 90px;
  justify-content: space-between;
  align-items: flex-end;
  border: none;
  background: none;
  user-select: none;
}

.MainDressViewer .avatar-toggle-image {
  position: absolute;
  margin: auto;
  right: 0;
  left: 0;
  user-select: none;
}

.MainDressViewer .avatar-toggle {
  z-index: 2;
  display: inline-block;
  width: 60px;
  height: 34px;
  border: none;
  background: none;
  cursor: pointer;
  user-select: none;
}

.MainDressViewer .reset-button {
  z-index: 2;
  display: inline-block;
  border: none;
  background: none;
  cursor: pointer;
  user-select: none;
}

.MainDressViewer .brandTag {
  color: white;
  font-size: 0.4em;
  letter-spacing: 1px;
  position: absolute;
  bottom: 5%;
  left: 18%;
  z-index: -1;
  user-select: none;
}

.MainDressViewer .brandTag p {
  margin: unset;
}

.MainDressViewer .helper-component {
  position: absolute;
  display: block;
  z-index: 3;
  margin: auto;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.MainDressViewer .overlay {
  background-color: black;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  position: absolute;
}

.overlay2 {
  background-color: black;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  position: absolute;
  z-index: 99;
}

.helper-component img {
  opacity: 1;
  position: absolute;
}

.helper-component .helper-nav-button {
  right: 10%;
  bottom: 27%;
}

.helper-component .helper-zoom {
  left: 25%;
  bottom: 45%;
}

.helper-component .helper-rotate {
  right: 24%;
  top: 25%;
}

.helper-component .helper-reset {
  right: 12%;
  top: 15%;
}

.helper-component .helper-toggle-avatar {
  right: 17%;
  top: 6.5%;
}

.helper-component .helper-close {
  bottom: 5%;
  margin: auto;
  left: 0;
  right: 0;
}

@media screen and (min-width: 451px) and (max-width: 820px) {
  .helper-component .helper-nav-button {
    right: 10%;
    bottom: 27%;
  }

  .helper-component .helper-zoom {
    left: 25%;
    bottom: 45%;
  }

  .helper-component .helper-rotate {
    right: 24%;
    top: 25%;
  }

  .helper-component .helper-reset {
    right: 13%;
    top: 15%;
  }

  .helper-component .helper-toggle-avatar {
    right: 20%;
    top: 6.5%;
  }
}

@media screen and (min-width: 451px) {
  .MainDressViewer {
    width: 500px !important;
    height: 500px !important;
  }
}

@media screen and (max-width: 450px) {
  .MainDressViewer {
    height: 500px !important;
  }

  .MainDressViewer .buttonImage {
    height: 70%;
  }

  /* .MainDressViewer .buttonImage:active{
    height: 60%;
  } */
  .MainDressViewer .buttonPressed {
    height: 2.3em;
  }

  .helper-component .helper-nav-button {
    right: 14%;
    bottom: 27%;
  }

  .helper-component .helper-zoom {
    left: 9%;
    bottom: 45%;
  }

  .helper-component .helper-rotate {
    right: 9%;
    top: 22%;
  }

  .helper-component .helper-reset {
    right: 18%;
    top: 15%;
  }

  .helper-component .helper-toggle-avatar {
    right: 30%;
    top: 6%;
  }

  .helper-component .helper-close {
    bottom: 3%;
  }
}