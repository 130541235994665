div .MDV {
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin: auto;
    display: block;
  }
  
  .MDV .canvas {
    position: unset !important;
    height: 100%;
    background: white;
    display: block;
    margin: auto;
  }
  
  .DressNav .dress-container {
    height: 100%;
    width: auto;
  }
  
  .DressNav .img {
    height: 100%;
    width: auto;
    margin: 0;
  }
  
  .MDV .buttonTop {
    /* top: 25%; */
    top: 23%;
  }
  .MDV .buttonBottom {
    /* bottom: 25%; */
    bottom: 44%;
    scale: 1.1;
  }
  
  .MDV .btnprev {
    left: 5%;
  }
  
  .MDV .btnnext {
    right: 5%;
  }
  
  /* .MDV .nav-buttons {
    display: flex;
  }
  
  .MDV .buttonContainerTop {
    width: 80%;
    position: absolute;
    top: 25%;
    z-index: 0;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
  
  .MDV .buttonContainerBottom {
    width: 80%;
    position: absolute;
    bottom: 25%;
    z-index: 0;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  } */
  
  .MDV .button-html {
    width: 100%;
  }
  
  .MDV .button-exit {
    margin: auto;
    display: block;
    z-index: 12;
    background: none;
    border: none;
  }
  
  .MDV .dress-container-button {
    height: 14em;
    width: auto;
    border: 1px solid grey;
    padding: 0px;
    background-color: white;
  }
  
  .MDV .button {
    border: 0;
    /* border: 1px solid #e7e7e7; */
    /* height: 50px;
    width: 50px; */
    height: 75px;
    width: 75px;
    padding: 3px;
    cursor: pointer;
    position: absolute;
    border-radius: 5px;
    background-color: #fff;
    /* box-shadow: 0 2px 20px rgba(0,0,0,.2); */
    transition: transform .3s ease;
    user-select: none;
  }
  
  .MDV .button.blinking {
    /* animation: blinkAnimation 1s infinite; */
    /* animation: swing 0.8s ease infinite; */
    animation: swing 3s ease infinite;
  }
  
  /* @keyframes swing {
    0%,
    100% { */
      /* transform: rotate(2.5deg); */
      /* transform: scale(1); */
    /* } */
    /* 50% { */
      /* transform: rotate(-2.5deg); */
      /* transform: scale(0.9); */
    /* } */
  /* } */
  @keyframes swing {
    0%,30%,100% {
      transform: scale(1);
    }
    10%{
      transform: scale(0.9);
    }
  }
  
  @keyframes blinkAnimation {
    0%, 50%, 100% {
      opacity: 1;
      transform: scale(1);
    }
    25%, 75% {
      opacity: 0;
      transform: scale(0.9);
    }
  }
  
  .MDV .buttonImage {
    /* height: 100%; */
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
  }
  
  .MDV .buttonPressed {
    /* height: 2.7em; */
    scale: 0.9;
  }
  
  .MDV .init-load-div {
    background-color: white;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 3;
    position: absolute;
  }
  
  .MDV .initial-loading-gif {
    height: 100%;
    position: absolute;
    top: 0;
    margin: auto;
    right: 0;
    left: 0;
    user-select: none;
  }
  
  .MDV .helper-toggle {
    left: 6%;
    position: absolute;
    top: 5.5%;
    z-index: 2;
    cursor: pointer;
    user-select: none;
  }

  .MDV .download-canvas {
    left: 6%;
    position: absolute;
    top: 12.5%;
    z-index: 2;
    cursor: pointer;
    user-select: none;
  }
  
  .MDV .avatar-toggle-reset-controls {
    right: 6%;
    position: absolute;
    top: 2%;
    display: flex;
    flex-direction: column;
    z-index: 2;
    height: 95px;
    width: 90px;
    justify-content: space-between;
    align-items: flex-end;
    border: none;
    background: none;
    user-select: none;
  }
  
  .MDV .avatar-toggle-image {
    position: absolute;
    margin: auto;
    right: 0;
    left: 0;
    user-select: none;
  }
  
  .MDV .avatar-toggle {
    z-index: 2;
    display: inline-block;
    width: 60px;
    height: 34px;
    border: none;
    background: none;
    cursor: pointer;
    user-select: none;
  }
  
  .MDV .reset-button {
    z-index: 2;
    display: inline-block;
    border: none;
    background: none;
    cursor: pointer;
    user-select: none;
  }
  
  .MDV .circularScroll {
    position: absolute;
    top: 240px;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto;
  }
  
  .MDV .circularScroll .customizeBtns{
    position: relative;
    top: -61px;
  }
  
  .MDV .circularScroll .customizeBtns .changeBtn {
    width: 120px;
    height: auto;
    cursor: pointer;
  }
  
  .MDV .brandTag {
    color: white;
    font-size: 0.4em;
    letter-spacing: 1px;
    position: absolute;
    bottom: 5%;
    left: 18%;
    z-index: -1;
    user-select: none;
  }
  
  .MDV .brandTag p {
    margin: unset;
  }
  
  .MDV .helper-component {
    position: absolute;
    display: block;
    z-index: 3;
    margin: auto;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  
  .MDV .overlay {
    background-color: black;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    position: absolute;
  }
  
  .overlay2 {
    background-color: black;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    position: absolute;
    z-index: 99;
  }
  
  .helper-component img {
    opacity: 1;
    position: absolute;
    user-select: none;
  }
  
  .helper-component .helper-nav-button {
    right: 10%;
    bottom: 27%;
  }
  
  .helper-component .helper-zoom {
    left: 25%;
    bottom: 45%;
  }
  
  .helper-component .helper-rotate {
    right: 24%;
    top: 25%;
  }
  
  .helper-component .helper-reset {
    right: 12%;
    top: 15%;
  }
  
  .helper-component .helper-toggle-avatar {
    right: 17%;
    top: 6.5%;
  }
  
  .helper-component .helper-close {
    bottom: 5%;
    margin: auto;
    left: 0;
    right: 0;
  }
  
  @media screen and (min-width: 451px) and (max-width: 820px) {
    .helper-component .helper-nav-button {
      right: 10%;
      bottom: 27%;
    }
  
    .helper-component .helper-zoom {
      left: 25%;
      bottom: 45%;
    }
  
    .helper-component .helper-rotate {
      right: 24%;
      top: 25%;
    }
  
    .helper-component .helper-reset {
      right: 13%;
      top: 15%;
    }
  
    .helper-component .helper-toggle-avatar {
      right: 20%;
      top: 6.5%;
    }
  }
  
  @media screen and (min-width: 451px) {
    .MDV {
      width: 500px !important;
      height: 500px !important;
    }
  }
  
  @media screen and (max-width: 450px) {
    .MDV {
      height: 500px !important;
    }
  
    .MDV .buttonImage {
      /* height: 70%; */
    }
    
    .MDV .buttonPressed {
      /* height: 2.3em; */
      scale: 0.9;
    }
  
    .helper-component .helper-nav-button {
      right: 14%;
      bottom: 27%;
    }
  
    .helper-component .helper-zoom {
      left: 9%;
      bottom: 45%;
    }
  
    .helper-component .helper-rotate {
      right: 9%;
      top: 22%;
    }
  
    .helper-component .helper-reset {
      right: 18%;
      top: 15%;
    }
  
    .helper-component .helper-toggle-avatar {
      right: 30%;
      top: 6%;
    }
  
    .helper-component .helper-close {
      bottom: 3%;
    }
  }